import React from 'react'
import { useTheme } from 'styled-components'

export const ToggleCheckmarkIcon = () => {
  const { colors } = useTheme()
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 3C2.91015 3 0 5.91015 0 9.5C0 13.0899 2.91015 16 6.5 16H13.5C17.0899 16 20 13.0899 20 9.5C20 5.91015 17.0899 3 13.5 3H6.5ZM13.5 15C16.5376 15 19 12.5376 19 9.5C19 6.46243 16.5376 4 13.5 4C10.4624 4 8 6.46243 8 9.5C8 12.5376 10.4624 15 13.5 15Z"
        fill={colors.primary}
      />
      <path
        d="M10.5 10L12.5 12L16.5 8"
        stroke={colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
