import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const IconWrapper = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`

const MwsConnectComplete = props => {
  return (
    <IconWrapper {...props} viewBox="0 0 220 121">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F3F5FF"
          d="M116.785 4.973c3.794 0 6.87 4.8 6.87 10.723 0 5.922-3.076 10.723-6.87 10.723h62.809c3.794 0 6.87 4.8 6.87 10.723 0 5.922-3.076 10.723-6.87 10.723h21.59c3.795 0 6.87 4.801 6.87 10.723 0 5.923-3.075 10.724-6.87 10.724h-18.646c-3.794 0-6.87 4.8-6.87 10.723 0 5.922 3.076 10.723 6.87 10.723h5.889c3.502 0 6.87 4.8 6.87 10.723 0 5.922-3.076 10.723-6.87 10.723h-51.033c-.505 0-.997-.085-1.472-.246a4.545 4.545 0 0 1-1.472.246H45.144c-3.794 0-6.87-4.8-6.87-10.723 0-5.922 3.076-10.723 6.87-10.723H6.87c-3.794 0-6.87-4.8-6.87-10.723 0-5.922 3.076-10.723 6.87-10.723h39.255c3.794 0 6.87-4.801 6.87-10.724 0-5.922-3.076-10.723-6.87-10.723H21.591c-3.794 0-6.87-4.8-6.87-10.723 0-5.922 3.076-10.723 6.87-10.723h39.255c-2.193 0-6.87-4.801-6.87-10.723 0-5.923 4.473-10.325 6.87-10.723 2.397-.398 52.145 0 55.94 0zm92.763 84.567c5.772 0 10.452 4.633 10.452 10.347 0 5.714-4.68 10.347-10.452 10.347-5.773 0-10.453-4.633-10.453-10.347 0-5.714 4.68-10.347 10.453-10.347z"
        />
        <g strokeWidth="2.5">
          <path
            fill="#FFF"
            stroke="#7088F0"
            strokeLinecap="round"
            d="M136.917 97.6c3.02-1.952 5.707-4.38 7.458-6.34m2.826-3.254a47.69 47.69 0 0 0 9.438-18.918 47.94 47.94 0 0 0 1.274-14.362 47.466 47.466 0 0 0-2.452-12.379 46.891 46.891 0 0 0-5.73-11.404 47.48 47.48 0 0 0-4.982-6.094 47.275 47.275 0 0 0-8.9-7.226 46.958 46.958 0 0 0-10.821-4.997 47.67 47.67 0 0 0-14.527-2.202c-3.82.015-7.643.487-11.38 1.416a47.67 47.67 0 0 0-12.165 4.883 48.177 48.177 0 0 0-9.55 7.176 48.04 48.04 0 0 0-9.004 11.892 47.737 47.737 0 0 0-4.742 13.264 47.922 47.922 0 0 0-.65 14.319 47.178 47.178 0 0 0 5.59 17.839 47.23 47.23 0 0 0 7.405 10.022 47.034 47.034 0 0 0 16.137 10.931 47.664 47.664 0 0 0 22.617 3.264 47.75 47.75 0 0 0 18.56-5.797"
          />
          <g stroke="#F57706" strokeLinecap="round" strokeLinejoin="round">
            <path d="M156.787 1.84V7.75M156.787 15.635v5.912M146.833 11.694h5.972M160.77 11.693h5.972" />
          </g>
          <g stroke="#F57706" strokeLinecap="round" strokeLinejoin="round">
            <path d="M56.245 103.336v3.942M56.245 115.16v3.942M48.28 111.219h3.982M60.226 111.22h3.982" />
          </g>
          <path
            fill="#FFF"
            stroke="#7088F0"
            strokeLinecap="round"
            d="M95.735 19.169a41.683 41.683 0 0 0-5.912 2.791 42.155 42.155 0 0 0-8.357 6.28 42.575 42.575 0 0 0-2.973 3.178m-2.867 3.821a41.715 41.715 0 0 0-3.318 6.012"
          />
          <path
            fill="#0FB682"
            fillOpacity=".5"
            fillRule="nonzero"
            stroke="#0FB682"
            d="M91.564 57.385c-1.45-1.293-3.699-1.193-5.023.222a3.413 3.413 0 0 0 .227 4.903l16.733 14.92c2.03 1.81 5.177 1.67 7.031-.311.078-.084.078-.084.153-.17l23.878-28.034a3.414 3.414 0 0 0-.46-4.887c-1.51-1.225-3.752-1.024-5.007.45l-22.45 26.355-15.082-13.448z"
          />
        </g>
      </g>
    </IconWrapper>
  )
}

MwsConnectComplete.defaultProps = {
  width: '220px',
  height: '121px'
}

MwsConnectComplete.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
}

export default MwsConnectComplete
