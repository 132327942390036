import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
`

const PaginationArrowIcon = props => {
  const { backgroundColor } = props
  return (
    <IconWrapper {...{ ...props, viewBox: '0 0 25 25' }}>
      <g fill={backgroundColor} fillRule="evenodd">
        <rect width="24" height="24" x=".5" y=".5" stroke="#222" rx="2" />
        <path fill="#222" d="M16 12.5l-6 4.5v-9z" />
      </g>
    </IconWrapper>
  )
}

PaginationArrowIcon.defaultProps = {
  backgroundColor: COLORS.white
}

PaginationArrowIcon.propTypes = {
  backgroundColor: PropTypes.string
}

export { PaginationArrowIcon }
