import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { COLORS } from 'COLORS'

const OuterCircle = styled.div`
  background-color: transparent;
  border: ${props => props.lineWidth} solid ${props => props.bgColor};
  opacity: 0.9;
  border-radius: ${props => props.size};
  top: 0;
  width: ${props => props.innerSize}px;
  height: ${props => props.innerSize}px;
  margin: 0 auto;
  position: relative;
  margin: 0 auto;
`

const Rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const RotateAnimation = css`
  ${Rotate} 1s infinite linear;
`

const InnerCircle = styled.div`
  background-color: transparent;
  border: ${props => props.lineWidth} solid ${props => props.color};
  opacity: 0.9;
  border-left: ${props => props.lineWidth} solid transparent;
  border-radius: ${props => props.size};
  top: -${props => props.lineWidth};
  left: -${props => props.lineWidth};
  width: ${props => props.innerSize}px;
  height: ${props => props.innerSize}px;
  margin: 0 auto;
  position: relative;
  animation: ${RotateAnimation};
`

export class ProgressRing extends Component {
  render() {
    const bgColor = this.props.bgColor || COLORS.grey300
    const colour = this.props.color || COLORS.primary
    const size = this.props.size || '20px'
    const lineWidth = this.props.lineWidth || '2px'
    const innerSize = Math.floor((parseInt(size, 10) / 100) * 92)
    const innerTop = parseInt(size, 10) + 10

    return (
      <OuterCircle
        bgColor={bgColor}
        className={this.props.className}
        lineWidth={lineWidth}
        size={size}
        innerSize={innerSize}
        innerTop={innerTop}>
        <InnerCircle
          lineWidth={lineWidth}
          size={size}
          innerSize={innerSize}
          innerTop={innerTop}
          color={colour}
        />
      </OuterCircle>
    )
  }
}
