import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ButtonType } from '@junglescout/edna'
import { TutorialModal } from 'ui_elements/TutorialModal/TutorialModal'

import { mountWistia } from 'services/wistia'

const Wrapper = styled.div`
  display: flex;

  [class*='ButtonWrapper'] {
    min-height: 30px;
  }
`

function WatchTutorial(props) {
  const {
    className,
    dataId,
    disableModal,
    modalDescription,
    modalSubtitle,
    closeAction,
    onClick,
    video,
    title
  } = props
  const { t } = useTranslation(['common'])

  const [videoModalOpen, setVideoModalOpen] = useState(false)

  useEffect(() => {
    mountWistia()
  }, [])

  function onTutorialClick() {
    setVideoModalOpen(!videoModalOpen)
  }

  function onModalClose() {
    setVideoModalOpen(false)
    if (closeAction) {
      closeAction()
    }
  }

  function handleClick() {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Wrapper onClick={handleClick} className={className}>
      <Button
        margin={0}
        data-id={dataId}
        btnType={ButtonType.SECONDARY_TEXT}
        iconName="VIDEO_RECORDER"
        onClick={onTutorialClick}>
        {t('common:WatchTutorial.title', 'Watch Tutorial')}
      </Button>
      {!disableModal && (
        <TutorialModal
          title={title}
          description={modalDescription}
          subtitle={modalSubtitle}
          videoId={video}
          isVisible={videoModalOpen}
          handleBackdropClick={onModalClose}
        />
      )}
    </Wrapper>
  )
}

WatchTutorial.defaultProps = {
  className: '',
  dataId: 'PropTypes.string',
  onClick() {},
  modalDescription: '',
  modalSubtitle: '',
  disableModal: false,
  title: '',
  closeAction: undefined
}

WatchTutorial.propTypes = {
  className: PropTypes.string,
  dataId: PropTypes.string,
  video: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  modalDescription: PropTypes.string,
  modalSubtitle: PropTypes.string,
  // Use this when you don't want to use the modal we provide with this component,
  // for example need to display a modal outside the scope of the originap parent
  disableModal: PropTypes.bool,
  title: PropTypes.string,
  closeAction: PropTypes.func
}

const WrappedComponent = withTranslation(['common'])(WatchTutorial)
export { WrappedComponent as WatchTutorial }
