import React from 'react'
import { useTheme } from 'styled-components'

export const PencilFilledIcon = () => {
  const { colors } = useTheme()
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M11.3403 5.04907L15.5827 9.29147C15.5827 9.29147 6.64123 18.233 5.95694 18.9173C5.27264 19.6016 3.87522 19.5785 2.48993 19.556C1.79435 19.5447 1.44071 19.1911 1.44071 19.1911C1.44071 19.1911 1.08728 18.8377 1.07579 18.1419C1.05291 16.7566 1.03052 15.3594 1.71454 14.6749C3.05016 13.3382 11.3403 5.04907 11.3403 5.04907Z"
          fill={colors.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5832 6.29124L16.4861 8.38826L14.4052 6.3073C14.3998 6.30197 14.3944 6.29662 14.389 6.29124C14.3836 6.28586 14.3783 6.28046 14.3729 6.27505L12.292 4.1941L14.389 2.09708C14.389 2.09706 14.389 2.09704 14.3891 2.09702C15.5473 0.938894 17.425 0.938915 18.5832 2.09708C19.7413 3.25524 19.7413 5.13299 18.5832 6.29118C18.5832 6.2912 18.5832 6.29122 18.5832 6.29124Z"
          fill={colors.primary}
        />
      </g>
    </svg>
  )
}
