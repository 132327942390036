import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

import { CloseIcon } from 'icons/CloseIcon/CloseIcon'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { media } from '../../../../../helpers/media_queries'

const ModalWrapper = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.grey900};
  opacity: ${props => (props.backdropOpacity ? props.backdropOpacity : '0.2')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 7400;
`

const calculateMaxWidth = props => {
  if (props.infoModal) return '500px'
  if (props.small) return '400px'
  if (props.presentationModal || props.medium) return '425px'
  if (props.wide) return '500px'
  if (props.extraWide) return '610px'
  if (props.preview) return '955px'
  return '98%'
}

const CustomFade = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const CustomFadeAnimation = css`
  0.5s ease-out 0s 0.5 ${CustomFade};
`

const ContentWrapper = styled.div`
  align-self: end;
  animation: ${CustomFadeAnimation};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px ${COLORS.grey100};
  position: fixed;
  ${props => props.contentWidth && `width: ${props.contentWidth};`}
  max-width: ${calculateMaxWidth};
  background-color: ${COLORS.white};
  z-index: 7401;
  display: ${props => (props.preview ? 'flex' : 'block')};
  border-radius: 4px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props => (props.padded ? 'padding: 5px 15px;' : '')};
  ${props =>
    props.preview
      ? `
  width: 80%;
  height: 100%;
  max-height: 655px;
  `
      : ''};
`

const buildHeaderBorder = props => {
  if (props.presentationModal) {
    return `border: none;`
  }
  return `border-bottom: 1px solid ${COLORS.grey100}`
}

const HeaderWrapper = styled.div`
  ${props =>
    props.stickyHeader &&
    `
   position: sticky;
   top: 0;
   background-color: ${COLORS.white};
 `}
`

const Header = styled.div`
  ${TEXT_STYLES.H2Black}
  text-align: left;
  color: ${props => (props.infoModal ? COLORS.primary : COLORS.grey900)};
  padding: 15px;
  ${buildHeaderBorder};
`

const Content = styled.div`
  ${TEXT_STYLES.BodyBlack}
  text-align: ${props => (props.center ? 'center' : 'left')};
  word-wrap: break-word;
  padding: ${props =>
    props.contentPadding
      ? props.contentPadding
      : props.small
      ? '20px'
      : '15px'};
  ${props => (props.preview ? 'width: 100%;' : '')};
  ${props =>
    props.presentationModal
      ? `img {
    margin: 0 auto 20px;
    display: block;
    max-height: 150px;
  }`
      : ''};
`

const CloseIconWrapper = styled.div`
  z-index: 7402;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  ${media.phone`right: 25px;`};
  path {
    fill: ${COLORS.grey900};
    stroke: ${COLORS.grey900};
  }
`

class Modal extends Component {
  constructor(props) {
    super(props)

    this.contentRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.backdropOpacity === '0') {
      document.addEventListener('mousedown', this.handleClickOutside)
    }
  }

  componentWillUnmount() {
    if (this.props.handleOnClose) this.props.handleOnClose()
    if (this.props.backdropOpacity === '0') {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }
  }

  handleBackdropClick = e => {
    if (this.props.isVisible && this.props.handleBackdropClick) {
      this.props.handleBackdropClick(e)
    }
  }

  handleCloseButtonClick = e => {
    if (this.props.handleCloseButtonClick) {
      this.props.handleCloseButtonClick(e)
    } else if (this.props.handleBackdropClick) {
      this.props.handleBackdropClick(e)
    }
  }

  handleClickOutside = event => {
    if (
      this.contentRef &&
      this.contentRef.current &&
      !this.contentRef.current.contains(event.target)
    ) {
      this.props.handleBackdropClick(event)
    }
  }

  render() {
    const {
      modalClasses,
      headerClasses,
      containerClasses,
      contentClasses,
      backdropClasses,
      title,
      isVisible,
      children,
      stickyHeader,
      backdropOpacity,
      closeIconColor,
      testId
    } = this.props

    if (!isVisible) {
      return ''
    }

    return (
      <ModalWrapper {...{ isVisible }} className={modalClasses}>
        {backdropOpacity === '0' ? null : (
          <Backdrop
            data-testid="modal-backdrop"
            className={backdropClasses}
            backdropOpacity={backdropOpacity}
            onClick={this.handleBackdropClick}
          />
        )}
        <ContentWrapper
          {...this.props}
          ref={this.contentRef}
          title={null}
          className={`${containerClasses || ''} ${this.props.className || ''}`}>
          <HeaderWrapper stickyHeader={stickyHeader}>
            {title && (
              <Header {...this.props} title={null} className={headerClasses}>
                {title}
              </Header>
            )}
            {!this.props.noClose && (
              <CloseIconWrapper onClick={this.handleCloseButtonClick}>
                <CloseIcon
                  color={closeIconColor}
                  data-testid={testId || 'close'}
                />
              </CloseIconWrapper>
            )}
          </HeaderWrapper>
          <Content {...this.props} title={null} className={contentClasses}>
            {children}
          </Content>
        </ContentWrapper>
      </ModalWrapper>
    )
  }
}

Modal.propTypes = {
  isVisible: PropTypes.bool,
  containerClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  title: PropTypes.any,
  handleOnClose: PropTypes.func,
  handleBackdropClick: PropTypes.func,
  handleCloseButtonClick: PropTypes.func,
  contentWidth: PropTypes.string,
  infoModal: PropTypes.bool,
  presentationModal: PropTypes.bool,
  preview: PropTypes.bool,
  small: PropTypes.bool,
  wide: PropTypes.bool,
  extraWide: PropTypes.bool,
  medium: PropTypes.bool,
  padded: PropTypes.bool,
  center: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  children: PropTypes.any.isRequired,
  noClose: PropTypes.bool,
  backdropOpacity: PropTypes.string,
  closeIconColor: PropTypes.string,
  contentPadding: PropTypes.string
}

export { Modal }
