import { TRANSITION_TIMING } from 'constants/sidebar'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.svg`
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ${TRANSITION_TIMING},
    height 0.3s ${TRANSITION_TIMING}, width 0.3s ${TRANSITION_TIMING};
  width: ${props => props.width};
  height: ${props => props.height};
`

const JungleScoutCobaltLogo = ({ opacity, width, height }) => {
  return (
    <Wrapper
      opacity={opacity}
      width={width}
      height={height}
      title="Cobalt Logo"
      viewBox="0 0 130 20">
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
          <path
            d="M.003 10.208v-.054c-.058-2.57.936-5.05 2.751-6.87C4.57 1.464 7.048.464 9.618.515c2.73-.103 5.378.95 7.292 2.9l-2.1 2.423c-1.363-1.434-3.244-2.265-5.223-2.307-3.538 0-6.154 2.923-6.154 6.577v.054c0 3.646 2.585 6.6 6.154 6.6 2.308 0 3.762-.908 5.385-2.393l2.107 2.123c-1.896 2.182-4.672 3.396-7.561 3.308-2.549.038-5.003-.963-6.798-2.772-1.795-1.81-2.776-4.272-2.717-6.82zM18.164 12.523v-.054c.114-4.047 3.42-7.273 7.47-7.288 4.05-.015 7.379 3.188 7.522 7.234v.054c-.007 1.978-.811 3.869-2.23 5.247-1.418 1.377-3.331 2.126-5.308 2.076-1.96.05-3.857-.694-5.26-2.062-1.403-1.37-2.195-3.247-2.194-5.207zm11.77 0v-.054c.05-1.179-.382-2.327-1.196-3.182-.814-.854-1.94-1.341-3.12-1.349-2.577 0-4.23 2.024-4.23 4.477v.054c-.045 1.17.386 2.306 1.193 3.154.806.847 1.92 1.333 3.09 1.346 1.168.015 2.289-.455 3.096-1.298.808-.843 1.23-1.982 1.166-3.148zM38.749 17.285v2.184h-3.223V0h3.223v7.692c1.034-1.648 2.846-2.646 4.792-2.638 3.3 0 6.523 2.615 6.523 7.3v.054c0 4.615-3.192 7.292-6.523 7.292-1.899.037-3.693-.867-4.792-2.415zm8.038-4.816v-.054c0-2.715-1.83-4.5-4.015-4.5-2.185 0-4.1 1.808-4.1 4.5v.054c-.072 1.145.324 2.27 1.097 3.118.773.848 1.856 1.347 3.003 1.382 2.23 0 4.015-1.73 4.015-4.5zM52.195 15.423v-.038c0-3.077 2.369-4.523 5.8-4.523 1.285-.013 2.563.184 3.784.584V11.1c0-2-1.223-3.077-3.492-3.077-1.385.005-2.754.304-4.015.877l-.854-2.53c1.656-.782 3.469-1.174 5.3-1.147 2.1 0 3.67.562 4.654 1.539 1.085 1.177 1.641 2.748 1.538 4.346v8.33h-3.146v-1.746c-1.138 1.36-2.843 2.117-4.615 2.046-2.616.024-4.954-1.492-4.954-4.315zm9.638-1.015v-.954c-1.023-.382-2.108-.572-3.2-.562-2.077 0-3.3.877-3.3 2.308v.054c0 1.354 1.2 2.13 2.746 2.13 2.131.04 3.754-1.192 3.754-2.976zM68.672 0L71.926 0 71.926 19.438 68.703 19.438zM76.495 15.585V8.177H74.71V5.385h1.785V1.538h3.223v3.847h3.785v2.761h-3.785v6.923c-.073.48.085.965.426 1.31.34.346.824.51 1.305.444.695.006 1.382-.158 2-.477v2.639c-.886.5-1.892.746-2.908.715-2.37.008-4.046-1.03-4.046-4.115z"
            transform="translate(.5) translate(45)"
          />
        </g>
        <path
          fill="#FFF"
          d="M15.018 0v1.587c-1.342 1.177-2.1 2.874-2.075 4.649v.05c0 1.874.681 3.303 2.07 4.351v1.899l-2.768 3.285c-.247.291-.216.72.066.973.322.282.66.546 1.01.792-.105.118-.212.237-.327.35C11.638 19.286 9.706 20 7.427 20 4.392 20 2.02 18.867.365 16.643l-.365-.5.456-.429 3.295-3.088.551-.509.487.572c.87 1.041 1.517 1.442 2.327 1.442.343 0 1.396 0 1.396-1.857V0h6.506zm5.788.391c3.144 0 5.597.832 7.601 2.43l-2.56 3.572c-1.69-1.173-3.533-1.804-5.172-1.804-1.245.01-1.852.531-1.852 1.182v.05c0 .832.627 1.203 3.188 1.773 4.25.912 6.88 2.275 6.88 5.692v.048c0 3.737-2.984 5.952-7.467 5.952-2.812 0-5.526-.759-7.668-2.256-.342-.238-.67-.495-.982-.77l2.165-2.557.686-.81c1.821 1.433 3.855 2.194 5.99 2.194 1.377 0 2.116-.471 2.116-1.251v-.052c0-.761-.607-1.171-3.116-1.753-2.328-.528-4.342-1.128-5.597-2.31-.863-.81-1.366-1.898-1.366-3.428v-.05c-.023-1.349.464-2.657 1.366-3.667C16.24 1.219 18.234.39 20.806.39z"
          transform="translate(.5)"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.111"
          d="M37 0.556L37 19.444"
          opacity=".5"
          transform="translate(.5)"
        />
      </g>
    </Wrapper>
  )
}

JungleScoutCobaltLogo.defaultProps = {
  opacity: 1,
  height: '20px',
  width: '131px'
}

JungleScoutCobaltLogo.propTypes = {
  opacity: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string
}

export { JungleScoutCobaltLogo }
