import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.size};
  height: ${props => props.size};
`

const QuestionIcon = props => {
  return (
    <IconWrapper {...{ ...props, viewBox: '0 0 15 15' }}>
      <g fill="none" fillRule="evenodd">
        <circle cx="7.5" cy="7.5" r="7.5" fill={COLORS.grey600} />
        <path
          fill={props.color || COLORS.white}
          d="M10.068 3.603c.621.402.932.965.932 1.69 0 .751-.254 1.33-.763 1.736-.509.407-1.179.65-2.01.73l-.045 1.338H6.686l-.03-2.001c.959-.106 1.644-.285 2.055-.537.41-.252.616-.608.616-1.067 0-.389-.154-.694-.462-.915-.308-.22-.76-.331-1.358-.331-.587 0-1.061.146-1.423.437-.362.292-.572.698-.631 1.22L4 5.28c.157-.69.545-1.242 1.167-1.657C5.787 3.208 6.602 3 7.61 3c1.018 0 1.837.201 2.458.603zm-1.842 6.773c.196.173.294.4.294.683 0 .292-.098.521-.294.69-.195.167-.46.251-.792.251-.342 0-.611-.084-.807-.252-.196-.168-.294-.397-.294-.69 0-.282.1-.51.301-.682.2-.172.467-.258.8-.258s.597.086.792.258z"
        />
      </g>
    </IconWrapper>
  )
}

QuestionIcon.defaultProps = {
  size: '16px'
}

QuestionIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
}

export { QuestionIcon }
